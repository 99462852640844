<script lang="ts">
import axios from 'axios';

export default {
    props: {
        url: {
            type: String,
            default: 'https://www.trippus.se/web/registration/Registration.aspx?view=registration&idcategory=AB0ILBCaex75bdx71spey06DIbJg2LV-b65f2HRtUahhJa7t-iFXBaSIowVBAR_Ztygp-Q2bfAD9&ln=eng'
        }
    },
    data: () => ({
        contactEmail : '',
        baseUrl: '',
        iframeUrl: '',
    }),
    methods: {
        getParameter() {
          const params = new URLSearchParams(window.location.search);
          this.contactEmail = params.get('email'); // Extract the email
        },
        updateIframeUrl() {

          if( this.contactEmail && this.validEmail(this.contactEmail) ){
            return this.iframeUrl = this.baseUrl + '&email=' + this.contactEmail;
          }

          return this.iframeUrl = this.baseUrl;

          console.log('No email');
        },
        validEmail: function (email: string) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
    },
    mounted() {

        // Use the prop to set the baseUrl if it is provided
        this.baseUrl = decodeURIComponent(this.url);
        

        this.getParameter(); // Call the method when the component is mounted

        this.updateIframeUrl();
    }
}

</script>
<template>
    <iframe v-if="iframeUrl" id="trippus" :src="iframeUrl" style="border: none;"></iframe>

    <div v-if="! iframeUrl" class="text-center mx-auto p-8 my-20 bg-white space-y-4 rounded-lg">
      <h2 class="heading-ml ">We need a valid email to continue!</h2>
      <p class="">Glad to hear you are interested in our lottery for the WIT Conference 2024,<br> but something is wrong with your email.</p>
      <a href="/raffle" class="button button--yellow">Please try again</a>
    </div>

</template>
