<template>
  <div :class="`relative w-max ${className}`" ref="menu">
    <button
      @click="toggleSocialMenu"
      aria-haspopup="true"
      :aria-expanded="isSocialMenuVisible"
      class="h-11 w-11 flex items-center justify-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
      >
        <title>Toggle Social Menu</title>
        <path
          d="M2 22C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V9C0 8.45 0.195833 7.97917 0.5875 7.5875C0.979167 7.19583 1.45 7 2 7H5V9H2V20H14V9H11V7H14C14.55 7 15.0208 7.19583 15.4125 7.5875C15.8042 7.97917 16 8.45 16 9V20C16 20.55 15.8042 21.0208 15.4125 21.4125C15.0208 21.8042 14.55 22 14 22H2ZM7 15V3.825L5.4 5.425L4 4L8 0L12 4L10.6 5.425L9 3.825V15H7Z"
          fill="white"
        />
      </svg>
    </button>

    <section
      :class="`bg-white absolute right-0 bottom-0 translate-y-full rounded-sm bg-blue-dark ${
        isSocialMenuVisible ? 'block' : 'hidden'
      }`"
    >
      <ul class="text-white w-max gap-y-px flex flex-col items-stretch share-list" role="menu">
        <li role="none">
          <button
            role="menuitem"
            class="w-full py-4 px-6 bg-purple hover:bg-dark-purple flex flex-row items-center gap-x-3"
            @click="shareOnFacebook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              aria-hidden="true"
              focusable="false"
              class="h-[1rem] mb-0.5"
            >
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
              <title>Share on Facebook</title>
              <path
                fill="#fff"
                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
              />
            </svg>
            Share on Facebook
          </button>
        </li>
        <li role="none">
          <button
            role="menuitem"
            class="w-full py-4 px-6 bg-purple hover:bg-dark-purple flex flex-row items-center gap-x-3"
            @click="shareOnLinkedIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              aria-hidden="true"
              focusable="false"
              class="h-[1rem] mb-0.5"
            >
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
              <title>Share on LinkedIn</title>
              <path
                fill="#fff"
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              />
            </svg>
            Share on LinkedIn
          </button>
        </li>
        <li role="none">
          <button
            role="menuitem"
            class="w-full py-4 px-6 bg-purple hover:bg-dark-purple flex flex-row items-center gap-x-3"
            @click.stop="copyToClipboard"
          >
            <svg
              v-if="!isCopied"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              aria-hidden="true"
              focusable="false"
              class="h-[1rem] mb-0.5"
            >
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
              <title>Copy link</title>
              <path
                fill="#fff"
                d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z"
              />
            </svg>

            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              aria-hidden="true"
              focusable="false"
              class="h-[1rem] mb-0.5"
            >
              <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
              <title>Link copied</title>
              <path
                fill="#fff"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              />
            </svg>
            {{ isCopied ? 'Link copied!' : 'Copy link' }}
          </button>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSocialMenuVisible: false,
      currentUrl: '',
      isCopied: false,
    }
  },
  mounted() {
    this.currentUrl = window.location.href
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    shareOnFacebook() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`,
        '_blank'
      )
    },
    shareOnLinkedIn() {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          this.currentUrl
        )}`,
        '_blank'
      )
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.currentUrl)

      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 2500)
    },
    toggleSocialMenu() {
      this.isSocialMenuVisible = !this.isSocialMenuVisible
    },
    handleClickOutside(event) {
      const menu = this.$refs.menu
      if (menu && !menu.contains(event.target)) {
        this.isSocialMenuVisible = false
      }
    },
  },
}
</script>
